import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import RedemptionsTable from '../../components/RedemptionsTable';

const Redemption = () => (
  <>
    <Segment>
      <Header as='h3'>管理兑换码</Header>
      <div className='overlay'></div>
      <RedemptionsTable />
    </Segment>
  </>
);

export default Redemption;
