import React, { useEffect, useState } from 'react';
import { Icon, Button, Form, Grid, Header, Image, Segment, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API, copy, showError, showInfo, showNotice, showSuccess } from '../helpers';
import { useSearchParams } from 'react-router-dom';

const PasswordResetConfirm = () => {
  const [inputs, setInputs] = useState({
    email: '',
    token: '',
  });
  const { email, token } = inputs;

  const [loading, setLoading] = useState(false);

  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const [newPassword, setNewPassword] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let token = searchParams.get('token');
    let email = searchParams.get('email');
    setInputs({
      token,
      email,
    });
  }, []);

  useEffect(() => {
    let countdownInterval = null;
    if (disableButton && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(30);
    }
    return () => clearInterval(countdownInterval); 
  }, [disableButton, countdown]);

  async function handleSubmit(e) {
    setDisableButton(true);
    if (!email) return;
    setLoading(true);
    const res = await API.post(`/api/user/reset`, {
      email,
      token,
    });
    const { success, message } = res.data;
    if (success) {
      let password = res.data.data;
      setNewPassword(password);
      await copy(password);
      showNotice(`新密码已复制到剪贴板：${password}`);
    } else {
      showError(message);
    }
    setLoading(false);
  }
  
  return (
  <>
    <div className="overlay"></div>
    <Grid textAlign='center' style={{ marginTop: '50px' }}>
      <Grid.Column style={{ maxWidth: 360 }}>
        <Form size='large'>
          <Segment style={{ borderRadius: '20px' }}>
            <Image src='/logo.png' className="logo" />
            <p className="login-text">密码重置确认</p>
            <Form.Input
              fluid
              icon='mail'
              iconPosition='left'
              placeholder='邮箱地址'
              name='email'
              value={email}
              readOnly
              className='rounded-input custom-input password-input'
            />
            {newPassword && (
              <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='新密码'
              name='newPassword'
              value={newPassword}
              readOnly
              onClick={(e) => {
                e.target.select();
                navigator.clipboard.writeText(newPassword);
                showNotice(`密码已复制到剪贴板：${newPassword}`);
              }}
            />            
            )}
            <Button
              color='blue'
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
              disabled={disableButton}
              style={{ 
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '22px',
                paddingRight: '22px',
                paddingTop: '17px',
                paddingBottom: '17px',
                textAlign: 'center',
                lineHeight: '16px',
                color: 'rgba(255, 255, 255, 1)',
                cursor: 'pointer',
                borderRadius: '12px',
                background: 'radial-gradient(63.48% 77.49% at 52.87% -6.77%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(92.19deg, #5056ED 1.84%, #2AA4F5 99.51%), #293040',
                boxShadow: 'inset 0 1px 1px rgba(255, 255, 255, 0.25)',
                fontSize: '15px',
              }}
            >
              {disableButton ? `密码重置完成` : '提交'}
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
              <Icon name='reply' style={{ lineHeight: '15px' }} /> 
              <Link to='/login' style={{ marginLeft: '5px', color: 'gray', textDecoration: 'none', alignSelf: 'center' }}>
                返回登录
              </Link>
            </div>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  </>
  );  
};

export default PasswordResetConfirm;
