import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import UsersTable from '../../components/UsersTable';

const User = () => (
  <>
    <Segment>
      <Header as='h3'>管理用户</Header>
      <div className='overlay'></div>
      <UsersTable />
    </Segment>
  </>
);

export default User;
