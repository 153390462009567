import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import CountUp from 'react-countup';
import imgBlueDollar from '../../images/blue_dollar.svg';
import imgShop from '../../images/shop.png';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const topUp = async () => {
    if (redemptionCode === '') {
      showInfo('请输入额度兑换码！');
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('充值成功！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('请求失败');
    } finally {
      setIsSubmitting(false);
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('超级管理员未设置充值链接！');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const getUserQuota = async () => {
    let res = await API.get(`/api/user/self`);
    const { success, message, data } = res.data;
    if (success) {
      setUserQuota(data.quota);
    } else {
      showError(message);
    }
  };

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();
  }, []);

  return (
    <>
      <div className='overlay'></div>
      <Segment style={{ backgroundColor: '#fff', borderRadius: '15px', maxWidth: '450px', margin: '0 auto' }}>
        <Header as='h3'>充值额度</Header>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={imgShop} style={{ marginBottom: 20, width: 200, height: 200 }} />
        </div>
        <Grid columns={1} stackable>
          <Grid.Row>
            <Grid.Column>
              <Statistic.Group widths='one'>
                <Statistic>
                  {/* <Statistic.Value style={{color: '#2c3e50', fontSize: '2em'}}>$<CountUp end={renderQuota(userQuota)} decimals={2} decimal="." /></Statistic.Value> */}
                  <div style={{
                    color: '#3877e5',
                    fontWeight: 'bold',
                    fontSize: '3em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <img src={imgBlueDollar} alt='$' style={{ marginRight: '5px', width: '50px' }} />
                    <CountUp end={renderQuota(userQuota)} decimals={2} decimal='.' />
                  </div>
                  <Statistic.Label style={{ color: '#7f8c8d', fontSize: '1.2em' }}>剩余额度</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Input
                  placeholder='请输入额度兑换码'
                  name='redemptionCode'
                  value={redemptionCode}
                  onChange={(e) => {
                    setRedemptionCode(e.target.value);
                  }}
                />
                <Button.Group attached style={{ borderRadius: '5px' }}>
                  <Button color='green' onClick={openTopUpLink} style={{
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    marginRight: '5px'
                  }}>
                    充值
                  </Button>
                  <Button color='yellow' onClick={topUp} disabled={isSubmitting} style={{
                    backgroundColor: '#568ce9',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    marginLeft: '5px'
                  }}>
                    {isSubmitting ? '兑换中...' : '兑换'}
                  </Button>
                </Button.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <p style={{ fontWeight: 'lighter', color: 'gray', fontSize: '13px', textAlign: 'center' }}>
          请点击充值以获取额度兑换码。
        </p>
      </Segment>
    </>
  );
};

export default TopUp;