import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Image, Modal, Segment } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, showError, showSuccess, showWarning } from '../helpers';
import { onGitHubOAuthClicked } from './utils';

const LoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    wechat_verification_code: ''
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const [userState, userDispatch] = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const [status, setStatus] = useState({});
  const logo = getLogo();

  useEffect(() => {
    if (searchParams.get('expired')) {
      showError('未登录或登录已过期，请重新登录！');
    }
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
    }
  }, []);

  const [showWeChatLoginModal, setShowWeChatLoginModal] = useState(false);

  const onWeChatLoginClicked = () => {
    setShowWeChatLoginModal(true);
  };

  const onSubmitWeChatVerificationCode = async () => {
    const res = await API.get(
      `/api/oauth/wechat?code=${inputs.wechat_verification_code}`
    );
    const { success, message, data } = res.data;
    if (success) {
      userDispatch({ type: 'login', payload: data });
      localStorage.setItem('user', JSON.stringify(data));
      navigate('/token');
      showSuccess('登录成功！');
      setShowWeChatLoginModal(false);
    } else {
      showError(message);
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (username && password) {
      setLoading(true);
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        if (username === 'root' && password === '123456') {
          navigate('/user/edit');
          showSuccess('登录成功！');
          showWarning('请立刻修改默认密码！');
        } else {
          navigate('/token');
          showSuccess('登录成功！');
        }
      } else {
        showError(message);
      }
      setLoading(false);
    }
  }

  return (
    <>
      <div className='overlay'></div>
      <Grid textAlign='center' style={{ marginTop: '50px' }}>
        <Grid.Column style={{ maxWidth: 360 }}>
          <Form size='large' className='login-form'>
            <Segment style={{ borderRadius: '20px' }}>
              <Image src={logo} className='logo' />
              <p className='login-text'>欢迎回来</p>
              <div style={{ marginBottom: '20px', marginTop: '-15px' }}>
                <span style={{ color: '#343434' }}>没有账户？</span>
                <Link to='/register' style={{ color: 'gray', textDecoration: 'none', alignSelf: 'center' }}>
                  立刻注册
                </Link>
              </div>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                placeholder='用户名'
                name='username'
                value={username}
                onChange={handleChange}
                className='rounded-input custom-input username-input'
              />
              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='密码'
                name='password'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChange}
                action={{
                  basic: true,
                  icon: showPassword ? 'eye slash' : 'eye',
                  style: { borderRadius: '0 12px 12px 0' },
                  onClick: () => setShowPassword(!showPassword)
                }}
                className='rounded-input custom-input password-input'
              />
              <Button
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: '22px',
                  paddingRight: '22px',
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  textAlign: 'center',
                  lineHeight: '16px',
                  color: 'rgba(255, 255, 255, 1)',
                  cursor: 'pointer',
                  borderRadius: '12px',
                  background: 'radial-gradient(63.48% 77.49% at 52.87% -6.77%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(92.19deg, #5056ED 1.84%, #2AA4F5 99.51%), #293040',
                  boxShadow: 'inset 0 1px 1px rgba(255, 255, 255, 0.25)',
                  fontSize: '15px'
                }}
                color='blue'
                fluid
                size='large'
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                {loading ? '正在登录……' : '登录'}
              </Button>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                <Link to='/reset' style={{ color: 'gray', textDecoration: 'none', alignSelf: 'center' }}>
                  忘记密码
                </Link>
              </div>
              <br />
            </Segment>
          </Form>

          {status.github_oauth || status.wechat_login ? (
            <>
              <Divider horizontal className='thin-text'>其他方式</Divider>
              {status.github_oauth ? (
                <Button
                  circular
                  color='black'
                  icon='github'
                  onClick={() => onGitHubOAuthClicked(status.github_client_id)}
                />
              ) : (
                <></>
              )}
              {status.wechat_login ? (
                <Button
                  circular
                  color='green'
                  icon='wechat'
                  onClick={onWeChatLoginClicked}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Modal
            onClose={() => setShowWeChatLoginModal(false)}
            onOpen={() => setShowWeChatLoginModal(true)}
            open={showWeChatLoginModal}
            size={'mini'}
          >
            <Modal.Content>
              <Modal.Description>
                <Image src={status.wechat_qrcode} fluid />
                <div style={{ textAlign: 'center' }}>
                  <p>
                    微信扫码关注公众号，输入「验证码」获取验证码（三分钟内有效）
                  </p>
                </div>
                <Form size='large'>
                  <Form.Input
                    fluid
                    placeholder='验证码'
                    name='wechat_verification_code'
                    value={inputs.wechat_verification_code}
                    onChange={handleChange}
                  />
                  <Button
                    color=''
                    fluid
                    size='large'
                    onClick={onSubmitWeChatVerificationCode}
                  >
                    登录
                  </Button>
                </Form>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LoginForm;
