import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Header, Label, Segment } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, showError, showSuccess, timestamp2string } from '../../helpers';
import CountUp from 'react-countup';
import { renderQuotaWithPrompt } from '../../helpers/render';
import imgBlueDollar from '../../images/blue_dollar.svg';

const EditToken = () => {
  const params = useParams();
  const tokenId = params.id;
  const isEdit = tokenId !== undefined;
  const [loading, setLoading] = useState(isEdit);
  const [numTokens, setNumTokens] = useState(1);
  const [neverExpire, setNeverExpire] = useState(true);
  const [models, setModels] = useState([]);
  const handleNeverExpiresChange = () => {
    setNeverExpire(!neverExpire);
    if (!neverExpire) {
      setExpiredTime(0, 0, 0, 0);
      setShowExpirationTime(false);
    } else {
      setExpiredTime(1, 0, 0, 0);
      setShowExpirationTime(true);
    }
  };
  const originInputs = {
    name: '默认令牌',
    remain_quota: isEdit ? 0 : 500000,
    expired_time: -1,
    unlimited_quota: true
  };
  const [inputs, setInputs] = useState(originInputs);
  const { name, remain_quota, expired_time, unlimited_quota } = inputs;
  const navigate = useNavigate();
  const handleInputChange = (e, { name, value }) => {
    if (name === 'expired_time') {
      let inputTime = new Date(value).getTime();
      let nowTime = new Date().getTime();
      if (inputTime < nowTime) {
        showError('过期时间必须在当前时间之后！');
        return;
      }
    }
    if (name === 'numTokens') {
      value = Math.max(1, parseInt(value));
    } else if (name === 'remain_quota') {
      value = Math.max(0, parseInt(value));
    }
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const handleCancel = () => {
    navigate('/token');
  };
  const setExpiredTime = (month, day, hour, minute) => {
    let now = new Date();
    let timestamp = now.getTime() / 1000;
    let seconds = month * 30 * 24 * 60 * 60;
    seconds += day * 24 * 60 * 60;
    seconds += hour * 60 * 60;
    seconds += minute * 60;
    if (seconds !== 0) {
      timestamp += seconds;
      if (timestamp < now.getTime() / 1000 + 300) {
        timestamp = now.getTime() / 1000 + 300;
      }
      setInputs({ ...inputs, expired_time: timestamp2string(timestamp) });
    } else {
      setInputs({ ...inputs, expired_time: -1 });
    }
  };

  const loadModels = async () => {
    let res = await API.get(`/api/user/available_model`);
    const { success, message, data } = res.data;
    if (success) {
      setModels(data);
    } else {
      showError(message);
    }
  };

  const setUnlimitedQuota = () => {
    setInputs({ ...inputs, unlimited_quota: !unlimited_quota });
  };

  const loadToken = async () => {
    let res = await API.get(`/api/token/${tokenId}`);
    const { success, message, data } = res.data;
    if (success) {
      if (data.expired_time !== -1) {
        data.expired_time = timestamp2string(data.expired_time);
        setShowExpirationTime(true);
        setNeverExpire(false);
      } else {
        setShowExpirationTime(false);
        setNeverExpire(true);
      }
      setInputs(data);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadModels().then();
    if (isEdit) {
      loadToken().then();
    } else {
      setExpirationOption('never');
      setExpiredTime(0, 0, 0, 0);
      setShowExpirationTime(false);
    }
  }, []);

  const submit = async () => {
    if (!isEdit && inputs.name === '') return;
    let localInputs = inputs;
    localInputs.remain_quota = parseInt(localInputs.remain_quota);
    if (localInputs.remain_quota < 0) {
      showError('额度必须大于等于 0！');
      return;
    }
    if (localInputs.expired_time !== -1) {
      let time = Date.parse(localInputs.expired_time);
      if (isNaN(time)) {
        showError('过期时间格式错误！');
        return;
      }
      let currentTime = new Date().getTime();
      if (time < currentTime) {
        showError('过期时间必须在当前时间之后！');
        return;
      }
      localInputs.expired_time = Math.ceil(time / 1000);
    }
    if (numTokens < 1) {
      showError('创建令牌数量必须大于等于 1！');
      return;
    }
    for (let i = 0; i < numTokens; i++) {
      let res;
      if (isEdit) {
        res = await API.put(`/api/token/`, { ...localInputs, id: parseInt(tokenId) });
      } else {
        res = await API.post(`/api/token/`, localInputs);
      }
      const { success, message } = res.data;
      if (!success) {
        showError(message);
        return;
      }
    }
    const successMessage = isEdit ? '令牌修改成功' : '令牌创建成功';
    showSuccess(`${successMessage}`);
    setInputs(originInputs);
    navigate('/token');
  };

  const [expirationOption, setExpirationOption] = useState('never');
  const [showExpirationTime, setShowExpirationTime] = useState(true);

  const handleExpirationChange = (e, { value }) => {
    setExpirationOption(value);
    switch (value) {
      case 'never':
        setExpiredTime(0, 0, 0, 0);
        setShowExpirationTime(false);
        break;
      case 'month':
        setExpiredTime(1, 0, 0, 0);
        setShowExpirationTime(true);
        break;
      case 'day':
        setExpiredTime(0, 1, 0, 0);
        setShowExpirationTime(true);
        break;
      case 'hour':
        setExpiredTime(0, 0, 1, 0);
        setShowExpirationTime(true);
        break;
      case 'minute':
        setExpiredTime(0, 0, 0, 1);
        setShowExpirationTime(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className='overlay'></div>
      <Segment loading={loading}
               style={{ backgroundColor: '#fff', borderRadius: '15px', maxWidth: '700px', margin: '0 auto' }}>
        <Header as='h3' style={{ padding: '10px' }}>{isEdit ? '更新令牌信息' : '创建新的令牌'}</Header>
        <Form autoComplete='new-password' style={{ padding: '20px' }}>
           {/*<Message style={{ borderRadius: '5px', borderColor: '#2185d0' }}>令牌的额度仅用于限制令牌本身的最大额度使用量，实际的使用受到账户的剩余额度限制。</Message>*/}
          <Form.Field>
            <label style={{ fontSize: '1.1em' }}>名称</label>
            <Form.Input
              name='name'
              placeholder='请输入名称'
              onChange={handleInputChange}
              value={name}
              autoComplete='new-password'
              required={!isEdit}
              style={{ borderRadius: '5px', borderColor: '#2185d0' }}
            />
          </Form.Field>
          <br />
          <Form.Field inline>
            <label style={{ fontSize: '1.1em', display: 'flex', alignItems: 'center' }}>
              永不过期
              <Checkbox
                toggle
                checked={neverExpire}
                onChange={handleNeverExpiresChange}
                style={{ marginLeft: '0.5em' }}
              />
            </label>
          </Form.Field>
          {showExpirationTime && (
            <Form.Field>
              <label style={{ color: 'gray', fontSize: '0.8em' }}>过期时间</label>
              <Form.Input
                name='expired_time'
                placeholder='请输入过期时间，格式为 yyyy-MM-dd HH:mm:ss，-1 表示无限制'
                onChange={handleInputChange}
                value={expired_time}
                autoComplete='new-password'
                type='datetime-local'
                style={{ borderRadius: '5px', borderColor: '#2185d0' }}
              />
            </Form.Field>
          )}
          <br />
          <Form.Field inline>
            <label style={{ fontSize: '1.1em', display: 'flex', alignItems: 'center' }}>
              无限额度
              <Checkbox
                toggle
                checked={unlimited_quota}
                onChange={() => {
                  setUnlimitedQuota();
                }}
                style={{ marginLeft: '0.5em' }}
              />
            </label>
            {unlimited_quota && (
              <p style={{
                color: 'gray',
                fontSize: '0.8em',
                fontWeight: 'lighter'
              }}>注意：启用无限额度后，已用额度将不再进行计算。</p>
            )}
          </Form.Field>
          {!unlimited_quota && (
            <Form.Field>
              <div style={{
                color: '#3877e5',
                fontWeight: 'bold',
                fontSize: '0.8em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <span style={{ marginRight: '1px' }}>等于</span>
                <img src={imgBlueDollar} alt='$' style={{ marginRight: '1px', width: '15px' }} />
                <CountUp end={renderQuotaWithPrompt(remain_quota)} decimals={2} decimal='.' />
              </div>
              {/* <label style={{ color: 'gray', fontSize: '0.8em' }}>{`额度  ${renderQuotaWithPrompt(remain_quota)}`}</label> */}
              <Form.Input
                name='remain_quota'
                placeholder='请输入额度（单位：token）'
                onChange={handleInputChange}
                value={remain_quota}
                autoComplete='new-password'
                type='number'
                disabled={unlimited_quota}
                style={{ borderRadius: '5px', borderColor: '#2185d0' }}
              />
            </Form.Field>
          )}
          <br />
          <Form.Field>
            <label style={{ fontSize: '1.1em' }}>可用模型</label>
            <Label.Group>
              {models.map(model => (
                <Label key={model}
                       style={{ margin: '3px', backgroundColor: '#0064FA', color: 'white', fontSize: '0.9em' }}>
                  {model}
                </Label>
              ))}
            </Label.Group>
          </Form.Field>
          <br />
          {!isEdit && (
            <Form.Field>
              <label style={{ fontSize: '1.1em' }}>创建令牌数量</label>
              <Form.Input
                name='numTokens'
                placeholder='请输入令牌数量'
                onChange={(e, { value }) => setNumTokens(value)}
                value={numTokens}
                autoComplete='new-password'
                type='number'
                min={1}
                style={{ borderRadius: '5px', borderColor: '#2185d0' }}
              />
            </Form.Field>
          )}
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCancel} style={{
              backgroundColor: '#db2828',
              color: 'white',
              borderRadius: '5px',
              margin: '10px'
            }}>取消</Button>
            <Button positive onClick={submit} style={{
              backgroundColor: '#2185d0',
              color: 'white',
              borderRadius: '5px',
              margin: '10px'
            }}>提交</Button>
          </div>
          <br />
          <p style={{
            color: 'gray',
            fontSize: '0.8em'
          }}>注意：令牌的额度仅用于限制令牌本身的最大额度使用量，实际的使用受到账户的剩余额度限制。</p>
        </Form>
      </Segment>
    </>
  );
};

export default EditToken;