import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Label, Popup, Segment, Select, Statistic, Table } from 'semantic-ui-react';
import { API, isAdmin, showError, timestamp2string } from '../helpers';
import { truncate } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';

import { ITEMS_PER_PAGE } from '../constants';
import { renderQuota } from '../helpers/render';
import CountUp from 'react-countup';
import imgBlueDollar from '../images/blue_dollar.svg';
import imgModelPrediction from '../images/model_prediction.svg';
import imgPrompt from '../images/prompt.svg';
import imgCompletion from '../images/completion.svg';

import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

function renderTimestamp(timestamp) {
  return (
    <>
      {timestamp2string(timestamp)}
    </>
  );
}

const MODE_OPTIONS = [
  { key: 'all', text: '全部用户', value: 'all' },
  { key: 'self', text: '当前用户', value: 'self' }
];

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <Rectangle {...props} radius={[8, 8, 0, 0]} />;
};

const LOG_OPTIONS = [
  { key: '0', text: '全部', value: 0 },
  { key: '1', text: '充值', value: 1 },
  { key: '2', text: '消费', value: 2 },
  { key: '3', text: '管理', value: 3 },
  { key: '4', text: '系统', value: 4 }
];

function renderType(type) {
  switch (type) {
    case 1:
      return <Label basic style={{ backgroundColor: '#A7EFB8', color: '#005955' }}> 充值 </Label>;
    case 2:
      return <Label basic style={{ backgroundColor: '#3877e5', color: 'white' }}> 消费 </Label>;
    case 3:
      return <Label basic style={{ backgroundColor: '#FFE2BD', color: '#CE7300' }}> 管理 </Label>;
    case 4:
      return <Label basic style={{ backgroundColor: '#FF818B', color: '#700009' }}> 系统 </Label>;
    default:
      return <Label basic style={{ backgroundColor: '#d1cfd1', color: '#393538' }}> 未知 </Label>;
  }
}

const LogsTable = () => {
  const [logs, setLogs] = useState([]);
  // const [showStat, setShowStat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [logType, setLogType] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [chartData, setChartData] = useState([]);
  const [showBarChart, setShowBarChart] = useState(true);
  const [pieChartData, setPieChartData] = useState([]);
  const [counts, setCounts] = useState({});
  const [otherModelsDetail, setOtherModelsDetail] = useState([]);
  const COLORS = ['#3877e5', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF0000', '#00FF00', '#0000FF'];

  const isAdminUser = isAdmin();
  let now = new Date();
  const [inputs, setInputs] = useState({
    username: '',
    token_name: '',
    model_name: '',
    start_timestamp: timestamp2string(0),
    end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
    channel: ''
  });
  const { username, token_name, model_name, start_timestamp, end_timestamp, channel } = inputs;

  const getCounts = async (type) => {
    let url = '';
    if (isAdminUser) {
      url = `/api/log/count?type=${type}`;
    } else {
      url = `/api/log/self/count?type=${type}`;
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      setCounts(data.count);
    } else {
      showError(message);
    }
  };

  const [stat, setStat] = useState({
    quota: 0,
    token: 0
  });

  const handleInputChange = (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const getPieChartData = async () => {
    let url = '';
    if (isAdminUser) {
      url = `/api/log/model_usage?username=${username}`;
    } else {
      url = `/api/log/self/model_usage`;
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      let total = 0;
      let others = 0;
      let otherModelsDetail = [];

      for (let key in data) {
        total += data[key];
      }

      const pieChartData = Object.keys(data).map((key) => {
        const value = data[key];
        if ((value / total) < 0.05) {
          others += value;
          otherModelsDetail.push({ name: key, value: data[key] });
          return null;
        } else {
          return {
            name: key,
            value: data[key]
          };
        }
      }).filter(item => item !== null);

      otherModelsDetail.sort((a, b) => b.value - a.value);

      pieChartData.push({ name: '其他模型', value: others, details: otherModelsDetail });

      setPieChartData(pieChartData);
    } else {
      showError(message);
    }
  };

  const getLogSelfStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(`/api/log/self/stat?type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`);
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const getLogStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(`/api/log/stat?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`);
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const loadLogs = async (startIdx, itemsPerPage = 10) => {
    let url = '';
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    if (isAdminUser) {
      url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&items_per_page=${itemsPerPage}&channel=${channel}`;
    } else {
      url = `/api/log/self/?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&items_per_page=${itemsPerPage}`;
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setLogs(data);
      } else {
        let newLogs = [...logs];
        newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
        setLogs(newLogs);
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const loadChartLogs = async (startIdx, itemsPerPage = 50) => {
    let url = '';
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    if (isAdminUser) {
      url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&items_per_page=${itemsPerPage}&channel=${channel}`;
    } else {
      url = `/api/log/self/?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&items_per_page=${itemsPerPage}`;
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      return data;
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const onPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
        // In this case we have to load more data and then append them.
        await loadLogs(activePage - 1);
      }
      setActivePage(activePage);
    })();
  };

  const refresh = async () => {
    if (searching) return;

    setLoading(true);
    setActivePage(1);
    await loadLogs(0);
    const ChartData = await loadChartLogs(0);
    if (isAdminUser) {
      getLogStat().then();
    } else {
      getLogSelfStat().then();
    }
    getCounts(logType).then();
    getPieChartData().then();

    const chartData = ChartData
      .filter((log) => log.type === 2)
      .map((log) => ({
        date: timestamp2string(log.created_at, false),
        quota: log.prompt_tokens + log.completion_tokens,
        model_name: log.model_name
      }))
      .reverse();
    setChartData(chartData);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    refresh().then();

    // const intervalId = setInterval(() => {
    //   if (!searching) {
    //     refresh();
    //   }
    // }, 120000);

    return () => {
      // clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    };
  }, [logType, searching]);

  const searchLogs = async () => {
    if (searchKeyword === '') {
      await loadLogs(0);
      setActivePage(1);
      setSearching(false);
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/log/self/search?keyword=${searchKeyword}`);
    const { success, message, data } = res.data;
    if (success) {
      setLogs(data);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  };

  const handleKeywordChange = async (e, { value }) => {
    setSearchKeyword(value.trim());
  };

  const sortLog = (key) => {
    if (logs.length === 0) return;
    setLoading(true);
    let sortedLogs = [...logs];
    if (typeof sortedLogs[0][key] === 'string') {
      sortedLogs.sort((a, b) => {
        return ('' + a[key]).localeCompare(b[key]);
      });
    } else {
      sortedLogs.sort((a, b) => {
        if (a[key] === b[key]) return 0;
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
      });
    }
    if (sortedLogs[0].id === logs[0].id) {
      sortedLogs.reverse();
    }
    setLogs(sortedLogs);
    setLoading(false);
  };

  function formatTokenName(tokenName) {
    if (tokenName.length <= 4) {
      return tokenName;
    }
    return tokenName.slice(0, 4) + '...';
  }

  const handleCopySuccess = () => {
    toast.success('复制成功');
  };

  const [hideFields, setHideFields] = useState(true);
  const [panelExpanded, setPanelExpanded] = useState(false);

  const togglePanel = () => {
    setPanelExpanded(!panelExpanded);
  };  

  const toggleHideFields = () => {
    setHideFields(!hideFields);
  };

  return (
    <>
      <Segment>
        <ToastContainer />
        <Grid columns={2} verticalAlign='middle' style={{ marginBottom: '-6px' }}>
          <Grid.Column width={8} verticalAlign='top'>
            <Header as='h3'>使用明细</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right' verticalAlign='top'>
            <Button icon basic onClick={refresh} loading={loading}
                    style={{ background: 'none', boxShadow: 'none', marginTop: '-5px' }}>
              <Icon name='refresh' />刷新
            </Button>
            {!isMobile && (
              <Button icon basic onClick={togglePanel}
                style={{ background: 'none', boxShadow: 'none', marginTop: '-5px', marginLeft: '5px' }}>
                {panelExpanded ? '收起面板' : '展开面板'}
              </Button>
            )}
            {
              isAdminUser && (
                <Button icon basic onClick={toggleHideFields}
                        style={{ background: 'none', boxShadow: 'none', marginTop: '-5px', marginLeft: '5px' }}>
                  {hideFields ? '显示查询选项' : '隐藏查询选项'}
                </Button>
              )
            }
          </Grid.Column>
        </Grid>
        <div className='overlay'></div>
        <Form style={{ marginBottom: '25px' }}>
          <Form.Group>
            {!hideFields && (
              <>
                {isAdminUser && (
                  <>
                    <Form.Input
                      fluid
                      label={'用户名称'}
                      width={2}
                      value={username}
                      placeholder={'可选值'}
                      name='username'
                      onChange={handleInputChange}
                    />
                    <Form.Input
                      fluid
                      label={'渠道 ID'}
                      width={2}
                      value={channel}
                      placeholder={'可选值'}
                      name='channel'
                      onChange={handleInputChange}
                    />
                  </>
                )}
                <Form.Input fluid label={'令牌名称'} width={isAdminUser ? 2 : 3} value={token_name}
                            placeholder={'可选值'} name='token_name' onChange={handleInputChange} />
                <Form.Input fluid label='模型名称' width={isAdminUser ? 2 : 3} value={model_name} placeholder='可选值'
                            name='model_name'
                            onChange={handleInputChange} />
                <Form.Input fluid label='起始时间' width={4} value={start_timestamp} type='datetime-local'
                            name='start_timestamp'
                            onChange={handleInputChange} />
                <Form.Input fluid label='结束时间' width={4} value={end_timestamp} type='datetime-local'
                            name='end_timestamp'
                            onChange={handleInputChange} />
                <Form.Button fluid label='操作' width={2} onClick={refresh}
                             style={{ backgroundColor: '#3877e5', color: 'white' }}>查询</Form.Button>
              </>
            )}
          </Form.Group>
        </Form>
        <div className={isMobile ? 'mobile-container' : 'desktop-container'} style={isMobile ? {} : { marginTop: '-20px', height: panelExpanded ? '550px' : '260px' }}>
          {!isMobile && chartData.length > 0 ? (
            <div className='chart-container' style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
              <Icon
                name={showBarChart ? 'eye slash' : 'eye'}
                onClick={() => setShowBarChart(!showBarChart)}
                title={showBarChart ? '隐藏条形图' : '显示条形图'}
                link
                style={{
                  position: 'absolute',
                  top: '19px',
                  left: '100px'
                }}
              />
              <div style={{ fontSize: '10px', color: 'gray', writingMode: 'vertical-rl', letterSpacing: "4px" }}>
                折线条形图只展示最新50条数据
              </div>
              <ComposedChart
                width={isMobile ? 300 : 450}
                height={250}
                data={chartData}
                margin={{
                  top: 5, right: 20, left: 0, bottom: 5
                }}
              >
                <CartesianGrid stroke='transparent' />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={(value, name, props) => {
                    const { model_name } = props.payload;
                    if (name === 'quota') {
                      return [`${value} tokens`, `模型名称: ${model_name}, 总消耗`];
                    }
                    return [value, name];
                  }}
                />
                <Legend align='right' verticalAlign='top' wrapperStyle={{ lineHeight: '20px', display: 'none' }} />
                {showBarChart && <Bar dataKey='quota' fill='#3877e5' shape={<CustomBar />} />}
                <Line type='monotone' dataKey='quota' stroke='#ff7300' />
              </ComposedChart>
              <PieChart width={isMobile ? 300 : 400} height={isMobile ? 300 : 400}>
                <Pie
                  data={pieChartData.filter(item => !(item.name === '其他模型' && item.value === 0))}
                  cx={200}
                  cy={118}
                  labelLine={false}
                  outerRadius={80}
                  fill='#8884d8'
                  dataKey='value'
                  label={({ name, percent }) => {
                    const truncatedName = name.length > 9 ? `${name.slice(0, 9)}...` : name;
                    return `${truncatedName} ${(percent * 100).toFixed(0)}%`;
                  }}
                  animationDuration={1000}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  content={({ payload }) => {
                    if (payload && payload.length && payload[0].name === '其他模型' && payload[0].value !== 0) {
                      const details = payload[0].payload.details;
                      return (
                        <div style={{
                          color: '#3877e5',
                          backgroundColor: '#fff',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '5px'
                        }}>
                          总共调用了 {payload[0].value} 次
                          {details.map((model, index) =>
                            <div key={index}>
                              {model.name}: {model.value} 次
                            </div>
                          )}
                        </div>
                      );
                    }
                    if (payload && payload.length) {
                      return (
                        <div style={{
                          color: '#3877e5',
                          backgroundColor: '#fff',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '5px'
                        }}>
                          总共调用了 {payload[0].value} 次 {payload[0].name}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
              <div className='stat-container'>
                <Statistic.Group widths='one'>
                  <Statistic>
                    <Statistic.Value style={{ textAlign: 'center' }}>
                      <div style={{
                        color: '#3877e5',
                        fontWeight: 'bold',
                        fontSize: renderQuota(stat.quota) > 1000 ? '0.6em' : '0.7em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <img src={imgBlueDollar} alt='$' style={{ marginRight: '5px', width: '50px' }} />
                        <CountUp end={renderQuota(stat.quota)} decimals={2} decimal='.' />
                      </div>
                    </Statistic.Value>
                    <Statistic.Label
                      style={{ color: '#7f8c8d', fontSize: '1.2em', textAlign: 'center' }}>总消耗额度
                    </Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </div>
            </div>
          ) : (
            !isMobile && (
              <div className='no-data-container'>
                <p style={{ marginTop: '100px' }}>暂无数据</p>
              </div>
            )
          )}
          {!isMobile && chartData.length > 0 ? (
            <div style={{ textAlign: 'center', fontWeight: 'bold', color: 'gray' }}>
              更多数据统计图形即将到来，敬请期待！
            </div>
          ) : (
            <></>
          )}
          { isMobile && (
            <div className='stat-container'>
              <Statistic.Group widths='one'>
                <Statistic>
                  <Statistic.Value style={{ textAlign: 'center' }}>
                    <div style={{
                      color: '#3877e5',
                      fontWeight: 'bold',
                      fontSize: renderQuota(stat.quota) > 1000 ? '0.6em' : '0.7em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <img src={imgBlueDollar} alt='$' style={{ marginRight: '5px', width: '50px' }} />
                      <CountUp end={renderQuota(stat.quota)} decimals={2} decimal='.' />
                    </div>
                  </Statistic.Value>
                  <Statistic.Label
                    style={{ color: '#7f8c8d', fontSize: '1.2em', textAlign: 'center' }}>总消耗额度
                  </Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </div>
          )}
        </div>
        <Table basic compact size='small'>
          <Table.Body>
            {logs
              .slice(
                (activePage - 1) * ITEMS_PER_PAGE,
                activePage * ITEMS_PER_PAGE
              )
              .map((log, idx) => {
                if (log.deleted) return <></>;
                const truncatedUsername = truncate(log.username, { length: 4 });
                return (
                  <Table.Row key={log.id}>
                    <Table.Cell
                      style={{ color: '#1955bd', fontWeight: 'lighter' }}>{renderTimestamp(log.created_at)}</Table.Cell>
                    {
                      isAdminUser && (
                        <Table.Cell>{log.username ? (
                          <>
                            <Popup
                              content={log.username}
                              trigger={
                                <Label>{truncatedUsername}</Label>
                              }
                            />
                            <CopyToClipboard text={log.username} onCopy={handleCopySuccess}>
                              <Icon name='copy' title='复制用户名' link style={{ color: '#0064FA' }} />
                            </CopyToClipboard>
                          </>
                        ) : ''}</Table.Cell>
                      )
                    }
                    <Table.Cell>{renderType(log.type)}</Table.Cell>
                    {isAdminUser && (
                      <Table.Cell>
                        {log.channel ? (
                          <Label basic title='渠道 ID' style={{ backgroundColor: '#ecf2fd', color: '#1955bd' }}>
                            {log.channel}
                          </Label>
                        ) : (
                          ''
                        )}
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      {log.token_name ? (
                        <Popup
                          content={
                            <div style={{ color: '#1955bd' }}>
                              {log.token_name}
                            </div>
                          }
                          trigger={
                            <Label basic style={{ backgroundColor: '#ecf2fd' }}>
                              <div style={{ color: '#1955bd' }}>
                                {formatTokenName(log.token_name)}
                              </div>
                            </Label>
                          }
                        />
                      ) : ''}
                    </Table.Cell>
                    <Table.Cell>
                      <div className='table-cell'>
                        {log.model_name ?
                          <Label basic style={{
                            backgroundColor: '#ecf2fd',
                            color: '#343434',
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <div style={{ width: '18px', height: '18px', marginRight: '5px' }}>
                              <img src={imgModelPrediction} alt='model'
                                   style={{ width: '100%', height: '100%' }} />
                            </div>
                            {log.model_name}
                          </Label>
                          : ''}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {log.type === 2 ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={imgPrompt} alt='prompt' style={{ marginRight: '5px', width: '16px' }} />
                          <span>{isAdminUser ? log.prompt_tokens : `提示：${log.prompt_tokens ? log.prompt_tokens : ''}`}</span>
                        </div>
                        : ' '}
                    </Table.Cell>
                    <Table.Cell>
                      {log.type === 2 ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={imgCompletion} alt='completion' style={{ marginRight: '5px', width: '20px' }} />
                          <span>{isAdminUser ? log.completion_tokens : `补全：${log.completion_tokens ? log.completion_tokens : ''}`}</span>
                        </div>
                        : ' '}
                    </Table.Cell>
                    <Table.Cell>
                      {log.type === 2 ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={imgBlueDollar} alt='$' style={{ marginRight: '5px', width: '20px' }} />
                          <span>{log.quota ? renderQuota(log.quota, 6) : ''}</span>
                        </div>
                        : ' '}
                    </Table.Cell>
                    <Table.Cell>{log.content}</Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>

          <Table.Footer>
            <Table.HeaderCell colSpan={'10'}>
              <div className='pagination-container'>
                <div>
                  {logs.length > 0 ? (
                    <span style={{ marginRight: '10px' }}>{`共 ${counts} 条数据`}</span>
                  ) : (
                    <span style={{ marginRight: '10px' }}>共 0 条数据</span>
                  )}
                  <Select
                    placeholder='选择明细分类'
                    options={LOG_OPTIONS}
                    className='select-container'
                    style={{ marginRight: '5px' }}
                    name='logType'
                    value={logType}
                    onChange={(e, { name, value }) => {
                      setLogType(value);
                      getCounts(value);
                    }}
                  />
                  <button
                    className='icon-button'
                    onClick={() => onPaginationChange(null, { activePage: activePage - 1 })}
                    disabled={activePage === 1}
                  >
                    <Icon name='chevron left' size='large' />
                  </button>
                  <span className='page-number'>{`${activePage} / ${counts === 0 ? 1 : Math.round(counts / 10)}`}</span>
                  <button
                    className='icon-button'
                    onClick={() => onPaginationChange(null, { activePage: activePage + 1 })}
                    disabled={activePage === Math.ceil(logs.length / ITEMS_PER_PAGE) + (logs.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}
                  >
                    <Icon name='chevron right' size='large' />
                  </button>
                </div>
              </div>
            </Table.HeaderCell>
          </Table.Footer>
        </Table>
      </Segment>
    </>
  );
};

export default LogsTable;
