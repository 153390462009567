import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Message,
  Modal
} from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, copy, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import { UserContext } from '../context/User';
import { onGitHubOAuthClicked } from './utils';
import imgUser from '../images/user.png';
import imgModelPrediction from '../images/model_prediction.svg';
import { renderGroup } from '../helpers/render';

function renderUserGroup(group) {
  switch (group) {
    case 'vip':
      return <Label style={{ backgroundColor: 'green', color: 'white' }}>VIP</Label>;
    case 'svip':
      return <Label style={{ backgroundColor: '#FF2400', color: 'white' }}>SVIP</Label>;
    default:
      return <Label style={{ backgroundColor: '#3877e5', color: 'white' }}>{group} 组</Label>
  }
}

const PersonalSetting = () => {
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();

  const [inputs, setInputs] = useState({
    wechat_verification_code: '',
    email_verification_code: '',
    email: '',
    self_account_deletion_confirmation: ''
  });
  const [status, setStatus] = useState({});
  const [showWeChatBindModal, setShowWeChatBindModal] = useState(false);
  const [showEmailBindModal, setShowEmailBindModal] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [affLink, setAffLink] = useState('');
  const [systemToken, setSystemToken] = useState('');
  const [models, setModels] = useState([]);

  const [userData, setUserData] = useState({
    username: '',
    display_name: '',
    email: '',
    github_id: '',
    wechat_id: '',
    group: '',
    role: ''
  });

  const loadModels = async () => {
    let res = await API.get(`/api/user/available_model`);
    const { success, message, data } = res.data;
    if (success) {
      setModels(data);
    } else {
      showError(message);
    }
  };

  useEffect(() => {
    loadModels().then();
    const fetchUserData = async () => {
      try {
        const res = await API.get('/api/user/self');
        const { success, data } = res.data;
        if (success) {
          setUserData(data);
        } else {
          showError('获取用户数据失败');
        }
      } catch (error) {
        showError('获取用户数据失败');
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  }, []);

  useEffect(() => {
    let countdownInterval = null;
    if (disableButton && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(30);
    }
    return () => clearInterval(countdownInterval); // Clean up on unmount
  }, [disableButton, countdown]);

  const handleInputChange = (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const generateAccessToken = async () => {
    const res = await API.get('/api/user/token');
    const { success, message, data } = res.data;
    if (success) {
      setSystemToken(data);
      setAffLink('');
      await copy(data);
      showSuccess(`令牌已重置并已复制到剪贴板`);
    } else {
      showError(message);
    }
  };

  const getAffLink = async () => {
    const res = await API.get('/api/user/aff');
    const { success, message, data } = res.data;
    if (success) {
      let link = `${window.location.origin}/register?aff=${data}`;
      setAffLink(link);
      setSystemToken('');
      await copy(link);
      showSuccess(`邀请链接已复制到剪切板`);
    } else {
      showError(message);
    }
  };

  const handleAffLinkClick = async (e) => {
    e.target.select();
    await copy(e.target.value);
    showSuccess(`邀请链接已复制到剪切板`);
  };

  const handleSystemTokenClick = async (e) => {
    e.target.select();
    await copy(e.target.value);
    showSuccess(`系统令牌已复制到剪切板`);
  };

  const deleteAccount = async () => {
    if (inputs.self_account_deletion_confirmation !== userState.user.username) {
      showError('请输入你的账户名以确认删除！');
      return;
    }

    const res = await API.delete('/api/user/self');
    const { success, message } = res.data;

    if (success) {
      showSuccess('账户已删除！');
      await API.get('/api/user/logout');
      userDispatch({ type: 'logout' });
      localStorage.removeItem('user');
      navigate('/login');
    } else {
      showError(message);
    }
  };

  const bindWeChat = async () => {
    if (inputs.wechat_verification_code === '') return;
    const res = await API.get(
      `/api/oauth/wechat/bind?code=${inputs.wechat_verification_code}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('微信账户绑定成功！');
      setShowWeChatBindModal(false);
    } else {
      showError(message);
    }
  };

  const sendVerificationCode = async () => {
    setDisableButton(true);
    if (inputs.email === '') return;
    if (turnstileEnabled && turnstileToken === '') {
      showInfo('请稍后几秒重试，Turnstile 正在检查用户环境！');
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('验证码发送成功，请检查邮箱！');
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const bindEmail = async () => {
    if (inputs.email_verification_code === '') return;
    setLoading(true);
    const res = await API.get(
      `/api/oauth/email/bind?email=${inputs.email}&code=${inputs.email_verification_code}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('邮箱账户绑定成功！');
      setShowEmailBindModal(false);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  return (
    <div style={{ lineHeight: '40px' }}>
      <Header as='h3'>个人信息</Header>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card className='user-card' centered style={{ width: '95%' }}>
          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Button as={Link} to={`/user/edit/`} className='icon-button-user' title='编辑个人信息' style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none'
            }}>
              <Icon name='edit' className='gray'  />
            </Button>
            <Button onClick={generateAccessToken} className='icon-button-user' title='生成系统访问令牌' style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none'
            }}>
              <Icon name='key' className='gray'  />
            </Button>
            <Button onClick={getAffLink} className='icon-button-user' title='复制邀请链接' style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none'
            }}>
              <Icon name='user plus' className='gray'  />
            </Button>
            <Button onClick={() => {
              setShowAccountDeleteModal(true);
            }} className='icon-button-user' title='删除个人帐户' style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none'
            }}>
              <Icon name='trash' className='red'  />
            </Button>
          </div>
          <Card.Content>
            <Grid>
              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Image src={imgUser} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12}>
                <Card.Header style={{ fontWeight: 'bolder', fontSize: '36px' }}>{userData.username}</Card.Header>
                <Card.Meta>
                  <span className='date'>
                    {userData.role === 1 && (
                      <Label style={{ backgroundColor: 'green', color: 'white' }}>
                        普通用户
                      </Label>
                    )}
                    {userData.role === 10 && (
                      <Label style={{ backgroundColor: '#FF2400', color: 'white' }}>
                        管理员
                      </Label>
                    )}
                    {userData.role === 100 && (
                      <Label style={{ backgroundColor: 'red', color: 'white' }}>
                        超级管理员
                      </Label>
                    )}
                    {renderGroup(userData.group)}
                    {/*<Label style={{ backgroundColor: '#3877e5', color: 'white' }}>{userData.group} 组</Label>*/}
                  </span>
                </Card.Meta>
              </Grid.Column>
            </Grid>
            <Card.Description style={{ lineHeight: '1.5', marginTop: '10px', fontSize: '15px' }}>
              {userData.display_name && (
                <>
                  <strong>显示名称: </strong>
                  {userData.display_name}
                  <br />
                </>
              )}
              {userData.email && (
                <>
                  <strong>邮箱地址: </strong>
                  {userData.email}
                  <br />
                </>
              )}
              {userData.github_id && (
                <>
                  <strong>GitHub 账号: </strong>
                  {userData.github_id}
                  <br />
                </>
              )}
              {userData.wechat_id && (
                <>
                  <strong>微信账号: </strong>
                  {userData.wechat_id}
                </>
              )}
            </Card.Description>
            <Card.Content extra>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {systemToken && (
                  <div>
                    <Button
                      fluid
                      onClick={handleSystemTokenClick}
                      style={{ backgroundColor: '#3877e5', color: 'white' }}
                    >
                      {systemToken}
                    </Button>
                  </div>
                )}
                {affLink && (
                  <div>
                    <input type='text' value={affLink} id='affLink' style={{ opacity: 0, position: 'absolute' }} />
                    <Button
                      fluid

                      onClick={handleAffLinkClick}
                      style={{ backgroundColor: '#3877e5', color: 'white' }}
                    >
                      {affLink}
                    </Button>
                  </div>
                )}
              </div>
            </Card.Content>
          </Card.Content>
        </Card>
      </div>
      <p style={{ color: 'gray', fontSize: '0.8em', textAlign: 'center', fontWeight: 'lighter', marginTop: '5px' }}>
        修改个人信息只允许在电脑端进行。生成的令牌用于系统管理，而非用于请求 OpenAI 相关的服务，请知悉。
      </p>
      <Divider />
      <Header as='h3'>可用模型</Header>
      <Label.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
        {models.map((model) => (
          <Label
            key={model}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '6px',
              backgroundColor: '#ecf2fd',
              color: '#343434',
              fontSize: '0.9em'
            }}
          >
            <div style={{ width: '18px', height: '18px', marginRight: '5px' }}>
              <img src={imgModelPrediction} alt='model' style={{ width: '100%', height: '100%' }} />
            </div>
            {model}
          </Label>
        ))}
      </Label.Group>
      <Divider />
      <Header as='h3'>账号绑定</Header>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', marginBottom: '5px' }}>
          {status.wechat_login && (
            <Button
              color='green'
              onClick={() => {
                setShowWeChatBindModal(true);
              }}
              style={{ width: '120px', fontSize: '12px' }}
            >
              <Icon name='wechat' />
              绑定微信
            </Button>
          )}
          {status.github_oauth && (
            <Button
              color='black'
              onClick={() => {
                onGitHubOAuthClicked(status.github_client_id);
              }}
              style={{ width: '120px', fontSize: '12px' }}
            >
              <Icon name='github' />
              绑定 GitHub
            </Button>
          )}
          <Button
            onClick={() => {
              setShowEmailBindModal(true);
            }}
            style={{ width: '120px', fontSize: '12px', color: 'white', backgroundColor: '#3877e5' }}
          >
            <Icon name='mail' />
            绑定邮箱
          </Button>
        </ButtonGroup>
      </div>
      <Modal
        onClose={() => setShowWeChatBindModal(false)}
        onOpen={() => setShowWeChatBindModal(true)}
        open={showWeChatBindModal}
        size={'mini'}
      >
        <Modal.Content>
          <Modal.Description>
            <Image src={status.wechat_qrcode} fluid centered />
            <div style={{ textAlign: 'center' }}>
              <p>
                微信扫码关注公众号，输入「验证码」获取验证码（三分钟内有效）
              </p>
            </div>
            <Form size='large'>
              <Form.Input
                fluid
                placeholder='验证码'
                name='wechat_verification_code'
                value={inputs.wechat_verification_code}
                onChange={handleInputChange}
              />
              <Button color='' fluid size='large' onClick={bindWeChat}>
                绑定
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setShowEmailBindModal(false)}
        onOpen={() => setShowEmailBindModal(true)}
        open={showEmailBindModal}
        size={'tiny'}
        style={{ maxWidth: '450px' }}
      >
        <Modal.Header>绑定邮箱地址</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='large'>
              <Form.Input
                fluid
                placeholder='输入邮箱地址'
                onChange={handleInputChange}
                name='email'
                type='email'
                action={
                  <Button onClick={sendVerificationCode} disabled={disableButton || loading}>
                    {disableButton ? `重新发送(${countdown})` : '获取验证码'}
                  </Button>
                }
              />
              <Form.Input
                fluid
                placeholder='验证码'
                name='email_verification_code'
                value={inputs.email_verification_code}
                onChange={handleInputChange}
              />
              {turnstileEnabled ? (
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                />
              ) : (
                <></>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                <Button
                  color=''
                  fluid
                  size='large'
                  onClick={bindEmail}
                  loading={loading}
                >
                  确认绑定
                </Button>
                <div style={{ width: '1rem' }}></div>
                <Button
                  fluid
                  size='large'
                  onClick={() => setShowEmailBindModal(false)}
                >
                  取消
                </Button>
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setShowAccountDeleteModal(false)}
        onOpen={() => setShowAccountDeleteModal(true)}
        open={showAccountDeleteModal}
        size={'tiny'}
        style={{ maxWidth: '450px' }}
      >
        <Modal.Header>危险操作</Modal.Header>
        <Modal.Content>
          <Message>您正在删除自己的帐户，将清空所有数据且不可恢复</Message>
          <Modal.Description>
            <Form size='large'>
              <Form.Input
                fluid
                placeholder={`输入你的账户名 ${userState?.user?.username} 以确认删除`}
                name='self_account_deletion_confirmation'
                value={inputs.self_account_deletion_confirmation}
                onChange={handleInputChange}
              />
              {turnstileEnabled ? (
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                />
              ) : (
                <></>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                <Button
                  color='red'
                  fluid
                  size='large'
                  onClick={deleteAccount}
                  loading={loading}
                >
                  确认删除
                </Button>
                <div style={{ width: '1rem' }}></div>
                <Button
                  fluid
                  size='large'
                  onClick={() => setShowAccountDeleteModal(false)}
                >
                  取消
                </Button>
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default PersonalSetting;
