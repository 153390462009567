import React, { useEffect, useState } from 'react';
import { Icon, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, getLogo, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';

const RegisterForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    verification_code: ''
  });
  const { username, password, password2 } = inputs;
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const logo = getLogo();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [verificationTimeout, setVerificationTimeout] = useState(0);

  let affCode = new URLSearchParams(window.location.search).get('aff');
  if (affCode) {
    localStorage.setItem('aff', affCode);
  }

  useEffect(() => {
    let status = localStorage.getItem('status');
    const timeout = localStorage.getItem('verificationTimeout');
    if (timeout) {
      const remainingTime = Math.max(Math.floor((timeout - Date.now()) / 1000), 0);
      setVerificationTimeout(remainingTime);
      const intervalId = setInterval(() => {
        setVerificationTimeout((prevTimeout) => {
          if (prevTimeout <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return Math.floor(prevTimeout - 1);
        });
      }, 1000);
    }
    if (status) {
      status = JSON.parse(status);
      setShowEmailVerification(status.email_verification);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  });

  let navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    if (password.length < 8) {
      showInfo('密码长度不得小于 8 位！');
      return;
    }
    if (password !== password2) {
      showInfo('两次输入的密码不一致');
      return;
    }
    if (username && password) {
      if (turnstileEnabled && turnstileToken === '') {
        showInfo('请稍后重试，浏览器环境检查未通过');
        return;
      }
      setLoading(true);
      if (!affCode) {
        affCode = localStorage.getItem('aff');
      }
      inputs.aff_code = affCode;
      const res = await API.post(
        `/api/user/register?turnstile=${turnstileToken}`,
        inputs
      );
      const { success, message } = res.data;
      if (success) {
        navigate('/login');
        showSuccess('注册成功！');
      } else {
        showError(message);
      }
      setLoading(false);
    }
  }

  const sendVerificationCode = async () => {
    if (inputs.email === '') {
      showError('请填写注册邮箱！');
      return;
    }
    if (turnstileEnabled && turnstileToken === '') {
      showInfo('请稍后重试，浏览器环境检查未通过');
      return;
    }
    if (verificationTimeout > 0) {
      showInfo(`请在${verificationTimeout}秒后再试`);
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      localStorage.setItem('verificationTimeout', Date.now() + 60 * 1000);
      showSuccess('验证码发送成功，请检查你的邮箱！');
      setVerificationTimeout(60);
      const intervalId = setInterval(() => {
        setVerificationTimeout((prevTimeout) => {
          if (prevTimeout <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTimeout - 1;
        });
      }, 1000);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  return (
  <>
    <div className="overlay"></div>
    <Grid textAlign='center' style={{ marginTop: '10px' }}>
      <Grid.Column style={{ maxWidth: 360 }}>
        <Form size='large'>
          <Segment style={{ borderRadius: '20px' }}>
            <Image src={logo} className="logo" />
            <p className="login-text">用户注册</p>
            <div style={{marginBottom: '20px',marginTop: '-15px'}}>
              <span style={{ color: '#343434' }}>已有账户？</span>
              <Link to='/login' style={{ color: 'gray', textDecoration: 'none', alignSelf: 'center' }}>
                返回登录
              </Link>
            </div>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='请输入用户名（最长 12 位）'
              onChange={handleChange}
              name='username'
              className='rounded-input'
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='请输入密码（最短 8 位，最长 20 位）'
              onChange={handleChange}
              name='password'
              type={showPassword ? 'text' : 'password'}
              className='rounded-input'
              action={{
                basic: true,
                style: {borderRadius: '0 12px 12px 0'},
                icon: showPassword ? 'eye slash' : 'eye',
                onClick: () => setShowPassword(!showPassword),
              }}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='请再次输入密码'
              onChange={handleChange}
              name='password2'
              type={showPassword2 ? 'text' : 'password'}
              className='rounded-input'
              action={{
                basic: true,
                style: {borderRadius: '0 12px 12px 0'},
                icon: showPassword2 ? 'eye slash' : 'eye',
                onClick: () => setShowPassword2(!showPassword2),
              }}
            />
            {showEmailVerification ? (
              <>
                <Form.Input
                  fluid
                  icon='mail'
                  iconPosition='left'
                  placeholder='请输入邮箱地址'
                  onChange={handleChange}
                  name='email'
                  type='email'
                  action={
                    <Button 
                      style={verificationTimeout > 0 ? {borderRadius: '0 12px 12px 0', border: '1px solid #ccc'} : {borderRadius: '0 12px 12px 0'}} 
                      basic 
                      onClick={sendVerificationCode} 
                      disabled={loading || verificationTimeout > 0}
                    >
                      {verificationTimeout > 0 ? `${verificationTimeout}秒后可重发` : '获取验证码'}
                    </Button>
                  }
                  className='rounded-input'
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='请输入邮箱验证码'
                  onChange={handleChange}
                  name='verification_code'
                  className='rounded-input'
                />
              </>
            ) : (
              <></>
            )}
            {turnstileEnabled ? (
              <Turnstile
                sitekey={turnstileSiteKey}
                onVerify={(token) => {
                  setTurnstileToken(token);
                }}
              />
            ) : (
              <></>
            )}
            <Button
                color='blue'
                fluid
                size='large'
                onClick={handleSubmit}
                loading={loading}
                style={{ 
                  position: 'relative',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: '22px',
                  paddingRight: '22px',
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  textAlign: 'center',
                  lineHeight: '16px',
                  color: 'rgba(255, 255, 255, 1)',
                  cursor: 'pointer',
                  borderRadius: '12px',
                  background: 'radial-gradient(63.48% 77.49% at 52.87% -6.77%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(92.19deg, #5056ED 1.84%, #2AA4F5 99.51%), #293040',
                  boxShadow: 'inset 0 1px 1px rgba(255, 255, 255, 0.25)',
                  fontSize: '15px',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
            >
                注册
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  </>
  );
};

export default RegisterForm;
