import React, { useEffect, useState } from 'react';
import { Icon, Button, Form, Grid, Header, Image, Segment, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';

const PasswordResetForm = () => {
  const [inputs, setInputs] = useState({
    email: ''
  });
  const { email } = inputs;

  const [loading, setLoading] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let countdownInterval = null;
    if (disableButton && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(30);
    }
    return () => clearInterval(countdownInterval);
  }, [disableButton, countdown]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    setDisableButton(true);
    if (!email) return;
    if (turnstileEnabled && turnstileToken === '') {
      showInfo('请稍后重试，浏览器环境检查未通过');
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/reset_password?email=${email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('重置邮件发送成功，请检查邮箱！');
      setInputs({ ...inputs, email: '' });
    } else {
      showError(message);
    }
    setLoading(false);
  }

  return (
  <>
    <div className="overlay"></div>
    <Grid textAlign='center' style={{ marginTop: '50px' }}>
      <Grid.Column style={{ maxWidth: 360 }}>
        <Form size='large'>
          <Segment style={{ borderRadius: '20px' }}>
            <Image src='/logo.png' className="logo" />
            <p className="login-text">密码重置</p>
            <Form.Input
              fluid
              icon='mail'
              iconPosition='left'
              placeholder='邮箱地址'
              name='email'
              value={email}
              onChange={handleChange}
              className='rounded-input custom-input password-input'
            />
            {turnstileEnabled ? (
              <Turnstile
                sitekey={turnstileSiteKey}
                onVerify={(token) => {
                  setTurnstileToken(token);
                }}
              />
            ) : (
              <></>
            )}
            <Button
              color='blue'
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
              disabled={disableButton}
              style={{ 
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '22px',
                paddingRight: '22px',
                paddingTop: '17px',
                paddingBottom: '17px',
                textAlign: 'center',
                lineHeight: '16px',
                color: 'rgba(255, 255, 255, 1)',
                cursor: 'pointer',
                borderRadius: '12px',
                background: 'radial-gradient(63.48% 77.49% at 52.87% -6.77%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(92.19deg, #5056ED 1.84%, #2AA4F5 99.51%), #293040',
                boxShadow: 'inset 0 1px 1px rgba(255, 255, 255, 0.25)',
                fontSize: '15px',
              }}
            >
              {disableButton ? `重试 (${countdown})` : '提交'}
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
              <Icon name='reply' style={{ lineHeight: '15px' }} /> 
              <Link to='/login' style={{ marginLeft: '5px', color: 'gray', textDecoration: 'none', alignSelf: 'center' }}>
                返回登录
              </Link>
            </div>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  </>
  );
};

export default PasswordResetForm;
